import React, { useEffect, useState } from "react";
import axios from "axios";

export const CoinsMarqueWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.livecoinwatch.com/static/lcw-widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="livecoinwatch-widget-5"
      lcw-base="USD"
      lcw-color-tx="#999999"
      lcw-marquee-1="coins"
      lcw-marquee-2="movers"
      lcw-marquee-items="30"
    ></div>
  );
};

export const LiveCoinWatch = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.livecoinwatch.com/static/lcw-widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="livecoinwatch-widget-3 test"
      lcw-base="USD"
      lcw-d-head="true"
      lcw-d-name="true"
      lcw-d-code="true"
      lcw-d-icon="true"
      lcw-color-tx="#ffffff"
      // lcw-color-bg="#1f2434"
      lcw-color-bg="transparent"
      lcw-border-w="0"
    ></div>
  );
};

export const LiveUSDTWatch = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.livecoinwatch.com/static/lcw-widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="livecoinwatch-widget-1 py-4 px-4"
      lcw-coin="USDT"
      lcw-base="USD"
      lcw-secondary="USDT"
      lcw-period="d"
      lcw-color-tx="#ffffff"
      lcw-color-pr="#58c7c5"
      lcw-color-bg="transparent"
      lcw-border-w="0"
      width="100%"
    ></div>
  );
};

// export const LiveUSDTPrice = () => {
//   useEffect(() => {
//     const script = document.createElement("script");
//     script.src = "https://static.coinstats.app/widgets/coin-price-widget.js";
//     script.async = true;
//     document.body.appendChild(script);

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return (
//     <coin-stats-ticker-widget
//       coin-id="tether"
//       locale="en"
//       currency="USD"
//       background="#1C1B1B"
//       text-color="#FFFFFF"
//       border-color="rgba(255,255,255,0.15)"
//       type="medium"
//       font="Roboto, Arial, Helvetica"
//       fontsize="12px"
//       height="100"
//       width="250"
//       rank-background="#FFA959"
//       status-up-color="#74D492"
//       status-down-color="#FE4747"
//       rank-text-color="#1C1B1B"
//     ></coin-stats-ticker-widget>
//   );
// };

export const LiveUSDTPrice = () => {
  const [info, setInfo] = useState(null);
  useEffect(() => {
    const interval = setInterval(async () => {
      // Call your function here
      // Replace functionName with the actual name of your function
      const data = await axios.get(
        "https://api.coin-stats.com/v2/widget/coin/tether?type=medium"
      );
      setInfo(data);
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    // <div className=" bg-[#1c1b1b] rounded-3xl border border-gray-400 px-4 py-6 max-sm:px-1 max-sm:py-2 text-white cursor-pointer flex justify-center flex-col">
    <div className="px-4 py-6 max-sm:px-1 max-sm:py-2 text-white cursor-pointer flex justify-center flex-col">
      <div className="sm:flex mb-4">
        <div className="sm:flex sm:mr-3 max-sm:text-center max-sm:mb-1 max-sm:ml-2">
          <img src={info?.data.ic} width="32px" height="32px" />
        </div>
        <div className=" text-sm font-bold opacity-80 flex items-center max-sm:text-center">
          <span>Tether (USDT)</span>
        </div>
      </div>
      <div className="sm:flex items-center max-sm:text-center">
        <div className="sm:flex flex-shrink-0 w-8 justify-center mr-3">
          <span></span>
        </div>
        <div className="2xl:flex">
          <span className=" text-2xl max-sm:text-sm font-light">
            ${Math.round(info?.data.pu * 1000000) / 1000000}
          </span>
          {info?.data.p24 > 0 ? (
            <div>
              <div className="flex ml-2 mt-2 max-sm:text-center">
                <div className="flex items-start mb-[3%] mt-[3%]">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      className="display: initial; max-width: initial;"
                    >
                      <path
                        fill="#74D492"
                        fill-rule="evenodd"
                        d="M8.894 5.789l2.382 4.764A1 1 0 0 1 10.382 12H5.618a1 1 0 0 1-.894-1.447l2.382-4.764a1 1 0 0 1 1.788 0z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div>
                  <span className="text-base max-sm:text-xs font-light text-green-300">
                    {info?.data.p24}%
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex ml-2 mt-2 max-sm:text-center">
                <div className="flex items-end mb-[3%] mt-[3%]">
                  <div className="transform rotate-180">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      className="display: initial; max-width: initial;"
                    >
                      <path
                        fill="#FE4747"
                        fill-rule="evenodd"
                        d="M8.894 5.789l2.382 4.764A1 1 0 0 1 10.382 12H5.618a1 1 0 0 1-.894-1.447l2.382-4.764a1 1 0 0 1 1.788 0z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div>
                  <span className="text-base max-sm:text-xs font-light text-red-300">
                    {info?.data.p24}%
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const LiveBTCPrice = () => {
  const [info, setInfo] = useState(null);
  useEffect(() => {
    const interval = setInterval(async () => {
      // Call your function here
      // Replace functionName with the actual name of your function
      const data = await axios.get(
        "https://api.coin-stats.com/v2/widget/coin/bitcoin?type=medium"
      );
      setInfo(data);
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    // <div className=" bg-[#1c1b1b] rounded-3xl border border-gray-400 px-4 py-6 max-sm:px-1 max-sm:py-2 text-white cursor-pointer flex justify-center flex-col">
    <div className="px-4 py-6 max-sm:px-1 max-sm:py-2 text-white cursor-pointer flex justify-center flex-col">
      <div className="sm:flex mb-4">
        <div className="sm:flex sm:mr-3 max-sm:text-center max-sm:mb-1 max-sm:ml-2">
          <img src={info?.data.ic} width="32px" height="32px" />
        </div>
        <div className=" text-sm font-bold opacity-80 flex items-center max-sm:text-center">
          <span>Bitcoin (BTC)</span>
        </div>
      </div>
      <div className="sm:flex items-center max-sm:text-center">
        <div className="sm:flex flex-shrink-0 w-8 justify-center mr-3">
          <span></span>
        </div>
        <div className="2xl:flex flex-wrap">
          <span className=" text-2xl max-sm:text-sm font-light">
            ${Math.round(info?.data.pu * 100) / 100}
          </span>
          {info?.data.p24 > 0 ? (
            <div>
              <div className="flex ml-2 mt-2 max-sm:text-center">
                <div className="flex items-start mb-[3%] mt-[3%]">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      className="display: initial; max-width: initial;"
                    >
                      <path
                        fill="#74D492"
                        fill-rule="evenodd"
                        d="M8.894 5.789l2.382 4.764A1 1 0 0 1 10.382 12H5.618a1 1 0 0 1-.894-1.447l2.382-4.764a1 1 0 0 1 1.788 0z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div>
                  <span className="text-base max-sm:text-xs font-light text-green-300">
                    {info?.data.p24}%
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex ml-2 mt-2 max-sm:text-center">
                <div className="flex items-end mb-[3%] mt-[3%]">
                  <div className="transform rotate-180">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      className="display: initial; max-width: initial;"
                    >
                      <path
                        fill="#FE4747"
                        fill-rule="evenodd"
                        d="M8.894 5.789l2.382 4.764A1 1 0 0 1 10.382 12H5.618a1 1 0 0 1-.894-1.447l2.382-4.764a1 1 0 0 1 1.788 0z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div>
                  <span className="text-base max-sm:text-xs font-light text-red-300">
                    {info?.data.p24}%
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const LiveETHPrice = () => {
  const [info, setInfo] = useState(null);
  useEffect(() => {
    const interval = setInterval(async () => {
      // Call your function here
      // Replace functionName with the actual name of your function
      const data = await axios.get(
        "https://api.coin-stats.com/v2/widget/coin/ethereum?type=medium"
      );
      setInfo(data);
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    // <div className=" bg-[#1c1b1b] rounded-3xl border border-gray-400 px-4 py-6 max-sm:px-1 max-sm:py-2 text-white cursor-pointer flex justify-center flex-col">
    <div className="px-4 py-6 max-sm:px-1 max-sm:py-2 text-white cursor-pointer flex justify-center flex-col">
      <div className="sm:flex mb-4">
        <div className="sm:flex sm:mr-3 max-sm:text-center max-sm:mb-1 max-sm:ml-2">
          <img src={info?.data.ic} width="32px" height="32px" />
        </div>
        <div className=" text-sm font-bold opacity-80 flex items-center max-sm:text-center">
          <span>Ethereum (ETH)</span>
        </div>
      </div>
      <div className="sm:flex items-center max-sm:text-center">
        <div className="sm:flex flex-shrink-0 w-8 justify-center mr-3">
          <span></span>
        </div>
        <div className="2xl:flex flex-wrap">
          <span className=" text-2xl max-sm:text-sm font-light">
            ${Math.round(info?.data.pu * 100) / 100}
          </span>
          {info?.data.p24 > 0 ? (
            <div>
              <div className="flex ml-2 mt-2 max-sm:text-center">
                <div className="flex items-start mb-[3%] mt-[3%]">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      className="display: initial; max-width: initial;"
                    >
                      <path
                        fill="#74D492"
                        fill-rule="evenodd"
                        d="M8.894 5.789l2.382 4.764A1 1 0 0 1 10.382 12H5.618a1 1 0 0 1-.894-1.447l2.382-4.764a1 1 0 0 1 1.788 0z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div>
                  <span className="text-base max-sm:text-xs font-light text-green-300">
                    {info?.data.p24}%
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="flex ml-2 mt-2 max-sm:text-center">
                <div className="flex items-end mb-[3%] mt-[3%]">
                  <div className="transform rotate-180">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      className="display: initial; max-width: initial;"
                    >
                      <path
                        fill="#FE4747"
                        fill-rule="evenodd"
                        d="M8.894 5.789l2.382 4.764A1 1 0 0 1 10.382 12H5.618a1 1 0 0 1-.894-1.447l2.382-4.764a1 1 0 0 1 1.788 0z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div>
                  <span className="text-base max-sm:text-xs font-light text-red-300">
                    {info?.data.p24}%
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// export const TawkToScript = () => {
//   useEffect(() => {
//     const s1 = document.createElement("script");
//     const s0 = document.getElementsByTagName("script")[0];
//     s1.async = true;
//     s1.src = "https://embed.tawk.to/64f40663a91e863a5c114e78/1h9cjhtpq";
//     s1.charset = "UTF-8";
//     s1.setAttribute("crossorigin", "*");
//     s0.parentNode.insertBefore(s1, s0);
//   }, []);

//   return null;
// };

// This is for work-miner(primary)
export const TawkToScript = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://embed.tawk.to/64f40663a91e863a5c114e78/1h9cjhtpq"; // This is for work-miner(primary)
    // script.src = "https://embed.tawk.to/651f3a5f6fcfe87d54b6f2a2/1hc0vo1er"; // This is for work-miner-clone(secondary)
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};
