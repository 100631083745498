import React, { useState } from "react";
import { toast } from "react-toastify";
import { useSetRecoilState } from "recoil";
import { atom_address, atom_email, isLoginShow } from "../atom";
import { useNavigate } from "react-router-dom";
import { getStores, saveStore } from "../api/crud";
import { IsEmpty } from "../utils/isEmpty";
import { ethers } from "ethers";

const Login = ({ loginSwitch }) => {
  const navigate = useNavigate();
  // const [email, setEmail] = useState("");
  // const setAtomEmail = useSetRecoilState(atom_email);
  const [address, setAddress] = useState("");
  const setAtomAddress = useSetRecoilState(atom_address);
  const setOpenModal = useSetRecoilState(isLoginShow);
  const loginHandle = async () => {
    let isAdmin = false;
    const userData = await getStores("AdminUser");
    Object.entries(userData).forEach((user) => {
      if (address === user[1].address) {
        // window.sessionStorage.setItem("email", email);
        window.sessionStorage.setItem("address", address);
        // setAtomEmail(email);
        setAtomAddress(address);
        navigate("/admin_dashboard", { replace: true });
        setOpenModal(false);
        isAdmin = true;
        return;
      }
    });
    if (isAdmin) return;
    // const goldData = await getStores("GoldTradeAdmin");
    // Object.entries(goldData).forEach((user) => {
    //   if (address === user[1].address) {
    //     // window.sessionStorage.setItem("email", email);
    //     window.sessionStorage.setItem("address", address);
    //     // setAtomEmail(email);
    //     setAtomAddress(address);
    //     navigate("/goldadmin_dashboard", { replace: true });
    //     setOpenModal(false);
    //     isAdmin = true;
    //     return;
    //   }
    // });
    // if (isAdmin) return;
    const clientData = await getStores("Client");
    if (!IsEmpty(clientData)) {
      // const clientUser = Object.entries(clientData).filter(
      //   (item) => item[1].email === email && item[1].address === address
      // );
      const clientUser = Object.entries(clientData).filter(
        (item) => item[1].address === address
      );
      if (clientUser.length > 0) {
        // window.sessionStorage.setItem("email", email);
        window.sessionStorage.setItem("address", address);
        // setAtomEmail(email);
        setAtomAddress(address);
        if (loginSwitch === 0) {
          navigate("/client_dashboard", { replace: true });
        } else {
          navigate("/goldtrade_dashboard", { replace: true });
        }
        setOpenModal(false);
      } else {
        toast("Invalid ETH address!");
        console.log("invalid");
      }
    } else {
      console.log("invalid dfasd");
      toast("Invalid ETH address!");
    }
  };

  const loginWithWalletHandle = async () => {
    if (!window.ethereum) {
      toast("Please install wallet");
      return;
    }
    // get the current account
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const walletAddress = await signer.getAddress();
      if (!walletAddress) {
        toast("Please connect your wallet");
        return;
      }
      const clientData = await getStores("Client");
      if (!IsEmpty(clientData)) {
        const clientUser = Object.entries(clientData).filter(
          (item) => item[1].address === walletAddress
        );
        if (clientUser.length > 0) {
          window.sessionStorage.setItem("address", walletAddress);
          setAtomAddress(walletAddress);
          if (loginSwitch === 0) {
            navigate("/client_dashboard", { replace: true });
          } else {
            navigate("/goldtrade_dashboard", { replace: true });
          }
          setOpenModal(false);
        } else {
          const data = {
            name: walletAddress,
            email: walletAddress,
            address: walletAddress,
            admin: "qaz127127",
            profit_active: "non_active",
            event: "show",
            reward: "show",
            withdraw_status: "unlocked",
            calc_status: "show",
            announce_status: "show",
            trade_balance: 0
          };
          const res = await saveStore("Client", data);
          if (res === true) {
            window.sessionStorage.setItem("address", walletAddress);
            setAtomAddress(walletAddress);
            if (loginSwitch === 0) {
              navigate("/client_dashboard", { replace: true });
            } else {
              navigate("/goldtrade_dashboard", { replace: true });
            }
            setOpenModal(false);
          } else {
            toast("Failed to register");
          }
        }
      } else {
        toast("Something went wrong!");
      }
    } catch (e) {
      toast("Please connect wallet first.");
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const trimmedValue = inputValue.replace(/\s/g, "");
    setAddress(trimmedValue);
  };
  //
  return (
    <div className="items-center">
      <div className="grid grid-rows-2 grid-cols-3 text-black text-lg font-normal font-sans gap-3">
        {/* <div>
          <p className=" py-3 text-right">Email:</p>
        </div>
        <div className="col-span-2">
          <input
            type="text"
            id="txt_email"
            name="txt_email"
            className="w-full rounded-sm border p-3"
            placeholder="Input Your Email Here."
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div> */}
        <div>
          <p className=" py-3 text-right">Wallet Address:</p>
        </div>
        <div className="col-span-2">
          <input
            type="text"
            id="txt_address"
            name="txt_address"
            className="w-full rounded-sm border p-3"
            // placeholder="Input Your ETH Address Here."
            value={address}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-span-3 text-right mt-2">
          <div className="flex space-x-2 justify-end">
            <button
              className="rounded-xl bg-red-800 shadow-md shadow-gray-700 px-6 py-3 text-white hover:bg-red-500"
              onClick={loginWithWalletHandle}
            >
              Log In With Wallet
            </button>
            <button
              className="rounded-xl bg-blue-800 shadow-md shadow-gray-700 px-6 py-3 text-white hover:bg-blue-500"
              onClick={loginHandle}
            >
              Log In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
